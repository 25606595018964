<template>
    <div class="shadow" v-if='$store.state.home.shadow'></div>
</template>

<script>
export default {
}
</script>

<style scoped>
    .shadow{
        position: fixed;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
        background: #ECF1F5;
        z-index: 900;
    }
</style>
