<template>
<div class="page">
    <home-shadow></home-shadow>
</div>
</template>

<script>
import HomeShadow from './Common/HomeShadow.vue'
export default {
    components: {
        HomeShadow
    },
    data() {
        return {}
    },
    methods: {
    },
    created() {
        let query = this.$route.query
        let session = query.session
        let urlModule = query.module
        let device = query.device // windows forclasswin  pad androidPadCKL
        let embed = query.embed // 是否显示头和尾
        let hidenav = query.hidenav //是否显示左侧导航
		let tool = query.tool
		
		if (this.isValid(device) && device != "") {
            sessionStorage["device"] = device
        }
        if (embed == 'true'&& urlModule !='zxbk') {
            sessionStorage["embed"] = embed
        }
        if (this.isValid(tool) && tool != '') {
            sessionStorage["tool"] = tool
        }
        
        let param = {
            session: session,
            page: 0,
            hostName: window.location.hostname
        }
		let api = this.getWebServiceURL7('LoginSessionJson')
        this.addToQueue(api, param, function(result, that) {
            that.stopLoading()
            that.$store.commit('CLOSE_HOME_SHADOW')
            if (that.isValidArray(result)) {
                var account = result[0];
                var realname = account.realname;
                    var showname = account.showname;
                    var username = (realname == null || realname == "") ? showname : realname;
                    var classidx = (account.classidx > 0 ? account.classidx : 0);
                    var classname = ((account.classname == null || account.classname == undefined) ? "" : account.classname);
                    var acnt = {
                        "userid": account.userid,
                        "session": account.session,
                        "loginname": account.showname,
                        "realname": account.realname,
                        "account": username,
                        "role": account.role,
                        "period": account.period,
                        "coin": account.coin,
                        "level": account.level,
                        "email": account.email,
                        "avatar": account.avatar,
                        "dlserver": account.dlserver,
                        "zoneidx": account.zoneidx,
                        "schoolidx": account.schoolidx,
                        "role1": account.role,
                        "classidx": classidx,
                        "classname": classname,
                        "zonename": account.zone,
                        "schoolname": account.school,
                        'zonelevel': account.zonelevel,
                        'platformzonelevel': account.platformzonelevel,
                        'zonelevelname': account.zonelevelname,
                        'showname': account.showname,
                        'sourceid': account.sourceid
                    };
                    that.saveAccount(acnt);
                    // that.getSubjectZonePeriod(acnt.session).then(res=>{
                        that.getUserPeriodSubject(function(){
                            let role = acnt.role,
                            accName = (acnt.showname == null || acnt.showname == "") ? acnt.loginname : acnt.showname;
                            if(role == '学生') {
                                switch (urlModule) {
                                    case 'list':
                                        that.$router.push({path: '/Res/Tbkc',})
                                        break;
                                }
                            } else if(role == '教师') {
                                switch (urlModule) {
                                    case "list":
                                        that.$router.push({path: `/Res/Tbkc`})
                                        break;
                                    case "tbcp":
                                        that.$router.push({path: '/Res/Tbcp',query:{hidenav:hidenav}})
                                        break;
                                    case "jbzy":
                                        that.$router.push({path: '/Res/Jbzy',query:{hidenav:hidenav}})
                                        break;
                                }
                            } else if(role == '家长') {
                                switch (urlModule) {
                                    case "personal":
                                        that.$router.push({path: '/Zone/User/Msg'})
                                        break;
                                    case "at":
                                        that.$router.push({path: '/Zone/User/Group/At'})
                                        break;
                                    case "classquan":
                                        that.$router.push({path: '/Zone/User/Group/Mine'})
                                        break;
                                }
                            } else if(role.indexOf("管理员") > 0 || role == "区域教研员") {
                                switch (urlModule) {
                                    case "personal":
                                        that.$router.push({path: '/Zone/User/Msg'})
                                        break;
                                }
                            }
                        })
                    // }).catch(msg=>{
                    //     console.log(msg);
                    //     this.$router.push({path:'/ErrorPage',query:{hidenav:'true'}})
                    // })
            } else {
                console.log('请检查服务器返回的session');
				sessionStorage.removeItem('account');
                that.setCookie('account', null, { expires: -1, domain: that.getDomain() });
				that.$store.commit({ type: 'UPDATE_ACCOUNT', account: { Account: null } });
			}
        }, this, false);
    }
}
</script>

<style scoped>
    .page
    {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 20%;
        background-position: center center;
    }
</style>